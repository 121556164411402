<template>
  <div class="p-3">
    <header class="d-flex flex-row justify-content-between mb-1">
      <h3>{{ translations.blocklist.page_title }}</h3>
      <span class="mr-3">
        <b-button @click="addPatientFormVisible = !addPatientFormVisible; shouldValidateAddPatientForm = null"
                  :disabled="loading"
                  variant="success">
          {{ translations.blocklist.add_patient_form.open_form_btn }}</b-button>
      </span>
    </header>

    <b-col>
      <b-collapse v-model="addPatientFormVisible" class="border">
        <b-col class="mb-2">
          <b-row class="pt-3">
            <b-col cols="3">
              <b-form-group class="p-0 m-0"
                            :label="translations.blocklist.add_patient_form.field_client"
                            :invalid-feedback="handleError('addPatientForm.client_id')"
                            :state="shouldValidateAddPatientForm && isValid('addPatientForm.client_id')">
                <b-form-select :disabled="loading"
                               v-model="$v.addPatientForm.client_id.$model"
                               :options="clientsDropdown"/>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group class="p-0 m-0"
                            :label="translations.blocklist.add_patient_form.field_patient_identification"
                            :invalid-feedback="handleError('addPatientForm.field')"
                            :state="shouldValidateAddPatientForm && isValid('addPatientForm.field')">
                <b-form-select :disabled="loading"
                               v-model="$v.addPatientForm.field.$model"
                               :options="addElementIdentificationOptions()"/>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group class="p-0 m-0"
                            :label="translations.blocklist.add_patient_form.field_value"
                            :invalid-feedback="handleError('addPatientForm.value')"
                            :state="shouldValidateAddPatientForm && isValid('addPatientForm.value')">
                <b-form-input v-model="$v.addPatientForm.value.$model"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group class="p-0 m-0"
                            :label="translations.blocklist.add_patient_form.field_reason"
                            :invalid-feedback="handleError('addPatientForm.reason')"
                            :state="shouldValidateAddPatientForm && isValid('addPatientForm.reason')">
                <b-form-textarea v-model="$v.addPatientForm.reason.$model"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button @click="onClickSaveAddPatientForm" :disabled="loading" variant="primary">
                {{ translations.blocklist.add_patient_form.save_btn }}</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-collapse>
    </b-col>

    <financial-list
      class="mt-4"
      v-model="currentPage"
      :header-data="TABLE_HEADERS"
      :events="TABLE_EVENTS"
      :data="elements"
      :disabled="loading"
      :items-per-page="itemsPerPage"
      :total="total"
      :no-items-message="translations.blocklist.table.empty_list"
      itemRefKey="id"
      @page-changed="loadBlocklist"
      @row-delete="deleteElement"/>
  </div>
</template>

<script>
import { version as uuidVersion } from 'uuid';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import errorHandler from '@/mixins/errorHandler';
import translations from '@/translations';
import { formatDatetime } from '@/helpers/finance';
import {
  maxLength, required, or, email, integer, minValue,
} from 'vuelidate/lib/validators';

export default {
  name: 'FinanceBlocklist',
  components: { FinancialList },
  mixins: [ errorHandler ],
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.blocklist.table.client, 'formatted_client'),
      associateHeaderDataItem(this.translations.blocklist.table.program_id, 'program_id'),
      associateHeaderDataItem(this.translations.blocklist.table.program_uuid, 'program_uuid'),
      associateHeaderDataItem(this.translations.blocklist.table.account_uuid, 'account_uuid'),
      associateHeaderDataItem(this.translations.blocklist.table.email, 'email'),
      associateHeaderDataItem(this.translations.blocklist.table.created_by, 'created_by'),
      associateHeaderDataItem(this.translations.blocklist.table.created_at, 'created_at'),
      associateHeaderDataItem(this.translations.blocklist.table.reason, 'reason'),
    ];
    this.TABLE_EVENTS = {
      'row-delete': {
        title: this.translations.blocklist.delete_btn,
        icon: 'trash',
      },
    };
  },
  data() {
    return {
      translations: translations.finance,
      loading: true,
      elements: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      clients: {},
      clientsDropdown: [],
      addPatientFormVisible: false,
      shouldValidateAddPatientForm: null,
      addPatientForm: {
        client_id: null,
        field: this.addElementIdentificationOptions()[0].value,
        value: null,
        reason: null,
      },
    };
  },
  async beforeMount() {
    await this.loadClientsInfo();
    await this.loadBlocklist(this.currentPage);
  },
  validations() {
    const fieldNot = fields => (_, formData) => !formData.field || !fields.includes(formData.field);
    const uuidV4Validate = uuid => {
      try {
        return uuidVersion(uuid) === 4;
      } catch (_) {
        return false;
      }
    };
    return {
      addPatientForm: {
        client_id: { required },
        field: { required },
        value: {
          required,
          // how to read this lines with OR: if field is in [ 'program_id' ], minValue(1) must be true
          'Minimum value is 1': or(fieldNot([ 'program_id' ]), minValue(1)),
          integer: or(fieldNot([ 'program_id' ]), integer),
          'Invalid UUID': or(fieldNot([ 'program_uuid', 'account_uuid' ]), uuidV4Validate),
          email: or(fieldNot([ 'email' ]), email),
        },
        reason: { required, maxLength: maxLength(200) },
      },
    };
  },
  methods: {
    async loadBlocklist(page) {
      this.loading = true;
      const offset = (page - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;
      try {
        const response = await this.$store.dispatch('Financial/getBlocklist', {
          limit,
          offset,
          order_by: 'id',
          order_descending: 'true',
        });
        let list = [];
        if (response && response.data && response.data.data) {
          this.total = response.data.data.total;
          list = response.data.data.items;
        } else {
          this.total = 0;
        }
        list.forEach(e => {
          e.program_id = e.program_id || '-';
          e.program_uuid = e.program_uuid || '-';
          e.account_uuid = e.account_uuid || '-';
          e.email = e.email || '-';
          e.created_at = formatDatetime(e.created_at);
          e.formatted_client = `${this.clients[e.client_id] || 'ERR_MISSING_NAME'} (ID: ${e.client_id})`;
        });
        this.elements = list;
      } catch (_) {
        this.$noty.error(`${this.translations.blocklist.error_loading}.`);
      } finally {
        this.loading = false;
      }
    },
    async loadClientsInfo() {
      try {
        const res = await this.$store.dispatch('Financial/getFilters');
        if (!res) {
          return;
        }

        this.clients = res.data.reduce((obj, c) => { obj[c.id] = c.display_name; return obj; }, {});
        this.clientsDropdown = res.data.map(c => ({ value: c.id, text: `${c.display_name} (ID: ${c.id})` }));
      } catch (e) {
        console.error('error loading filters', e);
        this.$noty.error(this.translations.blocklist.error_loading_filters);
      }
    },
    async onClickSaveAddPatientForm() {
      this.shouldValidateAddPatientForm = true;
      if (this.$v.addPatientForm.$invalid) {
        return;
      }

      this.loading = true;
      try {
        await this.$store.dispatch('Financial/addBlocklistElement', JSON.parse(JSON.stringify(this.addPatientForm)));
        this.resetAddPatientForm();
        this.shouldValidateAddPatientForm = null;
        this.currentPage = 1;
        await this.loadBlocklist(this.currentPage);
        this.$noty.success(this.translations.blocklist.add_patient_form.add_success);
        this.loading = false;
      } catch (err) {
        let errDetail = err;
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.blocklist.add_patient_form.add_error}: ${errDetail}`;
        this.$noty.error(errMsg);
        this.loading = false;
      }
    },
    async deleteElement(id) {
      if (!await this.$bvModal.msgBoxConfirm(this.translations.blocklist.delete_confirm, {
        okTitle: this.translations.blocklist.delete_confirm_ok_btn,
      })) {
        return;
      }

      this.loading = true;
      try {
        await this.$store.dispatch('Financial/deleteBlocklistElement', id);
        await this.loadBlocklist(this.currentPage);
        this.$noty.success(this.translations.blocklist.delete_success);
        this.loading = false;
      } catch (err) {
        let errDetail = err;
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.blocklist.delete_error}: ${errDetail}`;
        this.$noty.error(errMsg);
        this.loading = false;
      }
    },
    addElementIdentificationOptions() {
      return [
        { value: 'program_id', text: 'Program ID' },
        { value: 'program_uuid', text: 'Program UUID' },
        { value: 'account_uuid', text: 'Account UUID' },
        { value: 'email', text: 'Email' },
      ];
    },
    resetAddPatientForm() {
      this.addPatientForm.client_id = null;
      this.addPatientForm.field = this.addElementIdentificationOptions()[0].value;
      this.addPatientForm.value = null;
      this.addPatientForm.reason = null;
    },
  },
};
</script>
